<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addNewsFeedGalleryItemsModal' hide-footer>
        <addNewsFeedGalleryItems @closeAddNewsFeedGalleryItems='toggleAddNewsFeedGalleryItems()' @addNewsFeedGalleryItemsSuccess='addNewsFeedGalleryItemsSuccess()'></addNewsFeedGalleryItems>
      </b-modal>
      <b-modal ref='editNewsFeedGalleryItemsModal' hide-footer>
        <editNewsFeedGalleryItems
          :editingNewsFeedGalleryItems='currentNewsFeedGalleryItems'
          @closeEditNewsFeedGalleryItems='toggleEditNewsFeedGalleryItems()'
          @editNewsFeedGalleryItemsSuccess='editNewsFeedGalleryItemsSuccess()'
        ></editNewsFeedGalleryItems>
      </b-modal>

      <!-- <b-modal ref='bulkAddNewsFeedGalleryItemsModal' hide-footer>
        <bulkAddNewsFeedGalleryItems
          :editingNewsFeedGalleryItems='currentNewsFeedGalleryItems'
          @closebulkAddNewsFeedGalleryItems='toggleBulkAddNewsFeedGalleryItems()'
          @bulkAddNewsFeedGalleryItemsSuccess='bulkAddNewsFeedGalleryItemsSuccess()'
        ></bulkAddNewsFeedGalleryItems>
      </b-modal> -->


      <div class='row'>
        <h2>NewsFeedGalleryItems</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddNewsFeedGalleryItems()' type='button' class='btn btn-success'>Add</button>
      </div>
      <!-- <div class='row'>
        <button @click='toggleBulkAddNewsFeedGalleryItems()' type='button' class='btn btn-success'>Bulk Add</button>
      </div> -->
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered />
      </div>
    </div>
  </div>
</template>
<script>
import addNewsFeedGalleryItems from '../../components/addNewsFeedGalleryItems';
import editNewsFeedGalleryItems from '../../components/editNewsFeedGalleryItems';
//import bulkAddNewsFeedGalleryItems from '../../components/custom/bulkAddNewsFeedGalleryItems';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'News Feed Item',
                field: 'NewsFeedItemId',
                sort: 'asc',
              },
              {
                label: 'Image',
                field: 'Image',
                sort: 'asc',
              },
               {
                label: 'Display Order',
                field: 'DisplayOrder',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              }
      ],
      items: [],
      addNewsFeedGalleryItemsOpen: false,
      editNewsFeedGalleryItemsOpen: false,
      //bulkAddNewsFeedGalleryItemsOpen: false,
      currentNewsFeedGalleryItems: {}
    };
  },
  components: {
    addNewsFeedGalleryItems,
    editNewsFeedGalleryItems,
    //bulkAddNewsFeedGalleryItems,
    Datatable,
  },
  created: function() {
    this.getNewsFeedGalleryItems();
    this.addNewsFeedGalleryItemsOpen = false;
    this.editNewsFeedGalleryItemsOpen = false;
    //this.bulkAddNewsFeedGalleryItemsOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
 getNewsFeedGalleryItems() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getNewsFeedGalleryItems', payload);
    },
    editItem(NewsFeedGalleryItems) {
      this.toggleEditNewsFeedGalleryItems();
      this.currentNewsFeedGalleryItems = NewsFeedGalleryItems;
    },
    toggleAddNewsFeedGalleryItems() {
      if(this.addNewsFeedGalleryItemsOpen)
      {
        this.$refs.addNewsFeedGalleryItemsModal.hide()
      }
      else{
        this.$refs.addNewsFeedGalleryItemsModal.show()
      }
      this.addNewsFeedGalleryItemsOpen = !this.addNewsFeedGalleryItemsOpen;
    },
    addNewsFeedGalleryItemsSuccess() {
      this.toggleAddNewsFeedGalleryItems();
      miniToastr['success']('NewsFeedGalleryItems Added', 'Success', 1000, null);
      this.getNewsFeedGalleryItems();
    },
    toggleEditNewsFeedGalleryItems() {
      if(this.editNewsFeedGalleryItemsOpen)
      {
        this.$refs.editNewsFeedGalleryItemsModal.hide()
      }
      else{
        this.$refs.editNewsFeedGalleryItemsModal.show()
      }
      this.editNewsFeedGalleryItemsOpen = !this.editNewsFeedGalleryItemsOpen;
    },

    // toggleBulkAddNewsFeedGalleryItems() {
    //   if(this.bulkAddNewsFeedGalleryItemsOpen)
    //   {
    //     this.$refs.bulkAddNewsFeedGalleryItemsModal.hide()
    //   }
    //   else{
    //     this.$refs.bulkAddNewsFeedGalleryItemsModal.show()
    //   }
    //   this.bulkAddNewsFeedGalleryItemsOpen = !this.bulkAddNewsFeedGalleryItemsOpen;
    // },


    editNewsFeedGalleryItemsSuccess() {
      this.toggleEditNewsFeedGalleryItems();
      miniToastr['success']('NewsFeedGalleryItems Edited', 'Success', 1000, null);
      this.getNewsFeedGalleryItems();
    }
  }
};
</script>
<style scoped>
.NewsFeedGalleryItemsThumbnail {
  height: 50px;
}
</style>
